import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'dark'
      }
  // Add custom options here
})

export default vuetify
