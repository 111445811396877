import { createApp } from 'vue'
import App from './App.vue'
import { Amplify } from "aws-amplify";
import outputs from "../amplify_outputs.json";
import vuetify from './plugins/vuetify'

Amplify.configure(outputs);

const app = createApp(App)
app.use(vuetify)
app.mount('#app')
