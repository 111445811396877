<template>
  <v-card width="400" class="mx-auto mt-5">
    <v-card-title>File Upload</v-card-title>
    <v-card-text>
      <v-form>
        <v-file-input small-chips multiple v-model="files" label="Upload files">
        </v-file-input>
        <v-card-actions>
          <v-btn @click="uploadFiles">Upload</v-btn>
        </v-card-actions>
        {{ uploadStatus }}
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { uploadData } from 'aws-amplify/storage';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';

export default {
  data() {
    return {
      uploadStatus: "Nothing to upload",
      files: [],
    };
  },
  methods: {
    async uploadFiles() {
      const user = await getCurrentUser();
      console.log("User", user);
      const session = await fetchAuthSession();
      console.log("Session", session);
      for (let file of this.files) {
        this.uploadStatus = `Uploading ${file.name}`;
        console.log("Uploading file:", file);
        const fileName = `files/${user.userId}/${file.name}`;
        console.log("Uploading file:", fileName);
        const result = await uploadData({
          path: fileName,
          data: file,
          options: {
            onProgress: ({ loaded, total }) => {
              if (total) {
                console.log(`Uploaded ${loaded}/${total}`);
              }
            },
            metadata: {
              'Content-Type': file.type,
            },
          }
        }).result;
        console.log("Upload result:", result);
        this.uploadStatus = `Uploaded ${file.name} successfully`;
      }
    },
  },
};
</script>