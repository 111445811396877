<template>
  <v-app>
  <authenticator>
    <template v-slot="{ signOut, user }">
      Hello {{ user.signInDetails.loginId }}<br/>
      <v-btn @click="signOut">Sign Out</v-btn>
      <FileUpload />
    </template>
  </authenticator>
  </v-app>
</template>

<script>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import 'vuetify/styles'

import FileUpload from './components/FileUpload.vue'

export default {
  name: 'App',
  components: {
    Authenticator,
    FileUpload,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
